import clsx from "clsx";
import { Link } from "react-router-dom";
import { ReactComponent as LogoPlaystore } from "../assets/svg/logo_playstore.svg";

export function PlayStoreLink({ color = "black" }) {
    return (
        <Link
            to="https://play.google.com/store/apps/details?id=com.modim.s_human_healthcare"
            aria-label="GET IT ON Google Play"
            className={clsx(
                "rounded-lg transition-colors",
                color === "black" ? "bg-gray-800 text-white hover:bg-gray-900" : "bg-white text-gray-900 hover:bg-gray-50"
            )}
        >
            <LogoPlaystore />
        </Link>
    );
}
