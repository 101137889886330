import clsx from "clsx";
import { Container } from "../components/Container";
import { ReactComponent as Timer } from "../assets/svg/icon_orange_timer.svg";

const features = [
    {
        name: "소변시험지",
        description: ["비침습적인 방법으로 체내 활성산소 농도를 검사할 수 있는 도구로 스틱 형태의 시험지 끝부분에 소변을 묻히면 소변에서 검출된 MDA(Malondialdehyde) 양을 통해 체내 활성산소 농도를 알 수 있음 "],
        // icon: DeviceArrowIcon,
    },
    {
        name: "S휴먼헬스케어 측정판",
        description: ["스마트폰으로 촬영 시 발생할 수 있는 문제인 촬영 각도, 시험지 위치, 빛의 양 등을 자동으로 보정하여 검사 정확도를 높여주는 장치"],
        // icon: DeviceCardsIcon,
    },
    {
        name: "S휴먼헬스케어 모바일 앱",
        description: ["S휴먼헬스케어 항산화관리 시험지를 검사해 주고 결과를 알려주며, 저장된 데이터를 통해 내 몸의 변화를 한눈에 알아보기 위한 장치"],
        // icon: DeviceCardsIcon,
    },
];

export function Introduction() {
    return (
        <section id="introduction" className="border-t border-gray-00 bg-gray-50 py-20 sm:py-32">
            <Container>
                <div className="mx-auto text-center">
                    <div className=" h-100 rounded-2xl border border-stone-100 bg-stone-100 ">
                        <Timer className="mb-6 mt-10 mx-auto md:h-15" />

                        <h1 id="intro-title-small" className="text-gray-800 text-lg font-semibold tracking-tight">
                            S휴먼헬스케어 항산화관리는?<br />
                            {/* 소변 기반 스마트 자가 검사 키트로, 우리 몸의 산화 정도 즉, 노화의 각종 질병의 주범이라 알려진 ‘체내 활성산소 농도’를 쉽고 간단한 방법으로 검사할 수 있도록 해주는 솔루션입니다. */}

                            {/* S휴먼 헬스케어는 소변 기반 스마트 자가검사 키트로, 누구나 때와 장소를 가리지 않고 필요 할 때마다 내 몸의 건강 상태와
                        노화 상태를 확인 할 수 있는 솔루션입니다. */}
                        </h1>
                        <p className="mb-10 mx-10 mt-3 text-gray-800 text-1xl font-middle tracking-tight">

                            소변 기반 스마트 자가 검사 키트로, 우리 몸의 산화 정도 즉, 노화의 각종 질병의 <br />
                            주범이라 알려진 ‘체내 활성산소 농도’를 쉽고 간단한 방법으로 검사할 수 있도록 <br />
                            해주는 솔루션입니다.
                        </p>
                        {/* <h2 id="intro-title-large" className="text-3xl font-medium tracking-tight text-gray-900 mt-9">
                        누구나 간편하게 내 몸의 건강/노화 <br />
                        상태를 알 수 있도발판을 만들어 주기 위해
                    </h2>
                    <p className="mt-6 text-lg text-gray-600">
                        4년 간의 연구를 통해 수 없이 많은 시행착오를 겪으며 수본 헬스케어는 현대인들이 중요하게 여기는
                        <span className="text-blue-500 font-semibold"> 건강 관리</span>편과
                        <span className="text-red-500 font-semibold"> 노화 관리</span>편 두 가지 버전을 기획했습니다.
                    </p> */}
                        {/* <img className="mt-6 mx-auto h-64 w-64 rounded-full md:h-64 md:w-64" src="/images/product2.png" alt="" /> */}
                    </div>
                </div>
                <ul className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-16 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
                    {features.map((feature) => (
                        <li key={feature.name} className="text-center rounded-2xl border-2 border-gray-200 p-8">
                            <h3 className="mt-1 mb-3 text-lg font-bold text-gray-900">{feature.name}</h3>
                            <ul class="list-disc list-inside">
                                {feature.description.map((desc) => (
                                    <li className="list-none mt-1 font-normal text-gray-500">{desc}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
                {/* <div class="grid grid-cols-2 gap-4 mt-6 ">
                    <div
                        className={clsx(
                            "flex flex-col overflow-hidden rounded-2xl p-6 shadow-lg shadow-gray-900/5 justify-center bg-white"
                        )}
                    >
                        <p className={clsx("mt-3 text-sm text-gray-900 font-bold")}>
                            S휴먼 헬스케어 <span className="text-blue-500 font-semibold">건강관리</span>편: <br />
                            S휴먼 헬스케어는 기본에 충실하기 위해 소변 검사 기본 10가지 항목으로 <br /> 구성했습니다.
                        </p>
                        <p className={clsx("mt-3 text-sm italic text-gray-700")}>
                            *기본 10가지 항목이란? 잠혈, 빌리루빈, 우로빌리노겐, 케톤체, 단백질, 아질산염,
                            <br /> 포도당, pH, 비중, 백혈구를 가리키며 일반적으로 병원에서 하는 소변검사 항목과 동일합니다.
                        </p>
                    </div>
                    <img className="mx-auto h-64 w-64 md:h-64 md:w-64" src="/images/product1.png" alt="product" />
                </div>
                <div class="grid grid-cols-2 gap-4 mt-6 ">
                    <img className="mx-auto h-64 w-64 md:h-64 md:w-64" src="/images/product1.png" alt="product" />
                    <div
                        className={clsx(
                            "flex flex-col overflow-hidden rounded-2xl p-6 shadow-lg shadow-gray-900/5 justify-center bg-white"
                        )}
                    >
                        <p className={clsx("mt-3 text-sm text-gray-900 font-bold")}>
                            S휴먼 헬스케어 <span className="text-red-500 font-semibold">노화관리</span>편:
                        </p>
                        <p className={clsx("mt-3 text-sm first-letter", "text-gray-700")}>
                            노화의 주범인 활성산소의 체내 농도를 알기 위해 MDA (Malondialdehyde) 양을 측정할 수 있도록 구성했습니다.
                        </p>
                    </div>
                </div> */}
            </Container>
        </section>
    );
}
