import { Link } from "react-router-dom";
import { NavLinks } from "./NavLinks";
import { Container } from "./Container";
// import { Logo } from "./Logo";
import { ReactComponent as Logo } from "../assets/svg/logo_header.svg";
import { Popover } from "@headlessui/react";
// import { SelectLanguages } from "./SelectLanguages";

export function Header() {
    return (
        <header>
            <nav>
                <Container className="relative z-50 flex justify-between py-8">
                    <div className="relative z-10 flex items-center gap-16">
                        <Link to={"/"} aria-label="Home">
                            <Logo className="h-10 w-auto" />
                        </Link>
                        <div className="hidden lg:flex lg:gap-10">
                            <NavLinks />
                        </div>
                    </div>
                    <div className="flex items-center gap-6">
                        <Popover className="lg:hidden"></Popover>
                    </div>
                    {/* <Button href="#" className="hidden lg:block">
                        Download
                    </Button> */}
                    {/* <SelectLanguages /> */}
                </Container>
            </nav>
        </header>
    );
}
