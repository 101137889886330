import { AppStoreLink } from "./AppStoreLink";
import { CircleBackground } from "./CircleBackground";
import { Container } from "./Container";
import { PlayStoreLink } from "./PlayStoreLink";

export function CallToAction() {
    return (
        <section id="get-free-shares-today" className="relative overflow-hidden bg-gray-900 py-20 sm:py-28">
            <div className="absolute left-20 top-1/2 -translate-y-1/2 sm:left-1/2 sm:-translate-x-1/2">
                <CircleBackground color="#fff" className="animate-spin-slower" />
            </div>
            <Container className="relative">
                <div className="mx-auto max-w-md sm:text-center">
                    <h2 className="text-3xl  tracking-tight text-white sm:text-2xl font-medium">S휴먼헬스케어 모바일 앱 다운로드</h2>
                    <p className="mt-4 text-lg text-gray-400">
                    S휴먼헬스케어 항산화관리 시험지를 검사하고 결과를 알 수 있으며, 저장된 데이터를 통해 내 몸의 변화를 <br/>한눈에 알아보세요.
                    </p>
                    <div className="mt-8 flex justify-center">
                        <AppStoreLink color="white" />
                        <div className="mx-2" />
                        <PlayStoreLink color="white" />
                    </div>
                </div>
            </Container>
        </section>
    );
}
