import clsx from "clsx";
import { Link } from "react-router-dom";
import { ReactComponent as LogoAppStore } from "../assets/svg/logo_appstore.svg";

export function AppStoreLink({ color = "black" }) {
    return (
        <Link
            to="https://apps.apple.com/us/app/s%ED%9C%B4%EB%A8%BC%ED%97%AC%EC%8A%A4%EC%BC%80%EC%96%B4/id6474110839"
            aria-label="Download on the App Store"
            className={clsx(
                "rounded-lg transition-colors",
                color === "black" ? "bg-gray-800 text-white hover:bg-gray-900" : "bg-white text-gray-900 hover:bg-gray-50"
            )}
        >
            <LogoAppStore />
        </Link>
    );
}
