import { Container } from "../../components/Container";
import { getContent } from "./components/ContentWidget";

const contents = [
    {
        type: "header",
        content: "제1조(목적)",
    },
    {
        type: "para",
        content:
            "㈜모디엠(이하 ‘회사’라고 함) 회사가 제공하고자 하는 서비스(이하 ‘서비스’)를 이용하는 개인(이하 ‘회원’ 혹은 ‘서비스 이용자’)의 정보 이하(‘개인정보’)를 보호하기 위해, 개인정보보호법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’) 등 관련 법령을 준수하고, 서비스 이용자의 개인정보 보호 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침(이하’본 방침’)을 수립합니다.",
    },
    {
        type: "header",
        content: "제2조(개인정보 처리의 원칙)",
    },
    {
        type: "para",
        content:
            "개인정보 관련 법령 및 본 방침에 따라 회사는 이용자의 개인정보를 수집할 수 있으며 수집된 개인정보는 개인의 동이가 있는 경우에 한해 제 3자에게 제공될 수 있습니다. 단, 법령의 규정 등에 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.",
    },
    {
        type: "header",
        content: "제3조(본 방침의 공개)",
    },
    {
        type: "para",
        content:
            "회사는 이용자가 언제든지 쉽게 본 방침을 확인할 수 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 서비스 화면에 게시하거나 기타의 방법으로 공지함으로써 누구나 언제든지 열람할 수 있도록 하였습니다. 단, 법령의 규정 등에 의해 적법하게 강제되는 경우 회사는 수집한 이용자의 개인정보를 사전에 개인의 동의 없이 제3자에게 제공할 수도 있습니다.",
    },
    {
        type: "header",
        content: "제4조 (본 방침의 변경)",
    },
    {
        type: "para",
        content:
            "① 본 방침은 개인정보 관련 법령, 지침, 고시 또는 정부나 회사 서비스의 정책이나 내용의 변경에 따라 개정될 수 있습니다." +
            "\n②. 회사는 제1항에 따라 본 방침을 개정하는 경우 다음 각 호 하나 이상의 방법으로 공지합니다." +
            "\n가. 회사가 운영하는 인터넷 홈페이지의 첫 화면의 공지사항란 또는 별도의 창을 통하여 공지하는 방법." +
            "\n나. 서면•모사전송•전자우편 또는 이와 비슷한 방법으로 이용자에게 공지하는 방법" +
            "\n③ 회사는 제2항의 공지는 본 방침 개정의 시행일로부터 최소 7일 이전에 공지합니다. 다만, 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 공지합니다.",
    },
    {
        type: "header",
        content: "제5조 (회원 가입을 위한 정보)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 회사 서비스에 대한 회원가입을 위하여 다음과 같은 정보를 수집합니다." +
            "\n① 필수 수집 정보: 이메일 주소, 비밀번호, 이름, 생년월일 및 휴대폰 번호" +
            "\n② 선택 수집 정보: 키, 몸무게, 항산화관리(활성산소 검사) 검사 결과에 대한 모든 정보",
    },
    {
        type: "header",
        content: "제6조(본인 인증을 위한 정보)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 본인인증을 위하여 다음과 같은 정보를 수집합니다." +
            "\n① 필수 수집 정보: 휴대폰 번호, 이메일 주소, 이름, 생년월일, 성별, 본인확인값(CI, DI) 및 내/외국인 여부",
    },
    {
        type: "header",
        content: "제7조(결제 서비스를 위한 정보)",
    },
    {
        type: "para",
        content:
            "회사는 이용자에게 회사의 결제 서비스 제공을 위하여 다음과 같은 정보를 수집합니다." +
            "\n① 필수 수집 정보: 카드번호, 카드비밀번호, 유효기간, 생년월일 6자리(yy/mm/dd), 은행명 및 계좌번호",
    },
    {
        type: "header",
        content: "제8조(회사 서비스 제공을 위한 정보)",
    },
    {
        type: "para",
        content:
            "회사는 이용자에게 회사의 서비스를 제공하기 위하여 다음과 같은 정보를 수집합니다." +
            "\n① 필수 수집 정보: 아이디, 이메일 주소, 이름, 생년월일 및 연락처" +
            "\n②	개인 정보: 앱을 사용할 때 직접 제공하는 이름, 이메일 주소 또는 기타 연락처 정보와 같은 개인 정보를 수집할 수 있습니다." +
            "\n③	기기 정보: 모바일 기기에 관한 정보로, 모델, 운영 체제, 고유 기기 식별자(UDID) 및 기타 기술적 정보를 포함할 수 있습니다." +
            "\n④	카메라, 전화, 갤러리 권한: 앱은 특정 기능이나 기능을 제공하기 위해 기기의 카메라, 전화 및 갤러리에 대한 액세스 권한을 요청할 수 있습니다. 앱의 의도된 기능에 필요한 경우 이러한 권한에서 이미지, 오디오 또는 기타 데이터를 수집하고 처리할 수 있습니다." +
            "\n⑤	알림 권한: 앱은 업데이트, 경고 또는 기타 관련 정보를 위해 푸시 알림을 보낼 수 있습니다. 이러한 알림을 수신하지 않도록 하려면 기기 설정에서 설정을 변경할 수 있습니다." +
            "\n정보 사용 방법:" +
            "\n수집한 정보는 다음과 같은 목적으로 사용될 수 있습니다:" +
            "\n•	앱의 기능을 제공, 유지 및 개선하기 위해." +
            "\n•	당신과의 의사 소통, 요청에 응답 및 고객 지원 제공." +
            "\n•	업데이트, 뉴스 레터 및 프로모션 자료를 보내기 위해." +
            "\n•	사용 패턴 및 트렌드를 모니터링하고 분석하여 사용자 경험을 향상시키기 위해." +
            "\n정보 공개" +
            "\n다음과 같은 방법으로 정보를 공유할 수 있습니다:" +
            "\n•	우리가 운영하고 앱을 개선하는 데 도움을 주는 제삼자 서비스 제공업체와." +
            "\n•	당신의 동의나 기타 법률적 의무를 준수하기 위해." +
            "\n•	합병, 판매 또는 기타 비즈니스 거래와 관련하여." +
            "\n보안" +
            "\n우리는 정보 보호를 위한 합리적인 조치를 취하지만 인터넷을 통한 데이터 전송이나 전자 저장이 완전히 안전하지는 않습니다. 데이터의 보안을 보장할 수는 없습니다.",
    },
    {
        type: "header",
        content: "제9조(서비스 이용 및 부정 이용 확인을 위한 정보)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 서비스 이용 및 부정이용의 확인 및 분석을 위하여 다음과 같은 정보를 수집합니다." +
            "\n① 필수 수집 정보: 서비스 이용기록, 쿠키, 접속장소 정보 및 기기정보" +
            "\n※ 부정이용 : 회원탈퇴 후 재가입, 상품구매 후 구매취소 등을 반복적으로 행하는 등 회사가 제공하는 할인쿠폰, 이벤트 혜택 등의 경제상 이익을 불·편법적으로 수취하는 행위, 이용약관 등에서 금지하고 있는 행위, 명의도용 등의 불·편법행위 등을 말합니다. 수집되는 정보는 회사 서비스 이용에 따른 통계∙분석에 이용될 수 있습니다.",
    },
    {
        type: "header",
        content: "제10조(개인정보 수집 방법)",
    },
    {
        type: "para",
        content:
            "회사는 다음과 같은 방법으로 이용자의 개인정보를 수집합니다." +
            "\n① 어플리케이션 등 회사가 제공하는 홈페이지 외의 서비스를 통해 이용자가 자신의 개인정보를 입력하는 방식" +
            "\n① 이용자가 고객센터의 상담, 게시판에서의 활동 등 회사의 서비스를 이용하는 과정에서 이용자가 입력하는 방식",
    },
    {
        type: "header",
        content: "제11조(개인정보의 이용)",
    },
    {
        type: "para",
        content:
            "\n회사는 개인정보를 다음 각 호의 경우에 이용합니다." +
            "\n① 공지사항의 전달 등 회사의 운영에 필요한 경우" +
            "\n② 이용문의에 대한 회신, 불만의 처리 등 이용자에 대한 서비스 개선을 위한 경우" +
            "\n③ 회사의 서비스를 제공하기 위한 경우" +
            "\n④ 신규 서비스 개발을 위한 경우" +
            "\n⑤ 인구통계학적 분석, 서비스 방문 및 이용기록의 분석을 위한 경우" +
            "\n⑥ 개인정보 및 관심에 기반한 이용자간 관계의 형성을 위한 경우" +
            "\n⑦ 법령 및 회사 약관을 위반하는 회원에 대한 이용 제한 조치, 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 행위에 대한 방지 및 제재를 위한 경우",
    },
    {
        type: "header",
        content: "제12조(사전동의 등에 따른 개인정보의 제공)",
    },
    {
        type: "para",
        content:
            "\n① 회사는 개인정보 제3자 제공 금지에도 불구하고, 이용자가 사전에 공개하거나 다음 각호 사항에 대하여 동의한 경우에는 제3자에게 개인정보를 제공할 수 있습니다. 다만 이 경우에도 회사는 관련 법령 내에서 최소한으로 개인정보를 제공합니다." +
            "\n가. 수본 바이오에게 고객 서비스 고도화을 위하여 회원가입, 서비스 이용을 위해 수집한 정보, 항산화관리(활성산소 검사) 검사 결과에 대한 모든 정보를 제공" +
            "\n② 회사는 전항의 제3자 제공 관계에 변화가 있거나 제3자 제공 관계가 종결될 때도 같은 절차에 의해 이용자에게 고지 및 동의를 구합니다.",
    },
    {
        type: "header",
        content: "제13조(개인정보의 보유 및 이용기간)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 개인정보에 대해 개인정보의 수집·이용 목적이 달성을 위한 기간 동안 개인정보를 보유 및 이용합니다." +
            "\n전항에도 불구하고 회사는 내부 방침에 의해 서비스 부정이용기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴 시점으로부터 최대 1년간 보관합니다.",
    },
    {
        type: "header",
        content: "제14조(법령에 따른 개인정보의 보유 및 이용기간)",
    },
    {
        type: "para",
        content:
            "회사는 관계법령에 따라 다음과 같이 개인정보를 보유 및 이용합니다." +
            "\n전자상거래 등에서의 소비자보호에 관한 법률에 따른 보유정보 및 보유기간" +
            "\n계약 또는 청약철회 등에 관한 기록 : 5년" +
            "\n대금결제 및 재화 등의 공급에 관한 기록 : 5년" +
            "\n소비자의 불만 또는 분쟁처리에 관한 기록 : 3년" +
            "\n표시•홍보(광고)에 관한 기록 : 6개월" +
            "\n통신비밀보호법에 따른 보유정보 및 보유기간" +
            "\n웹사이트 로그 기록 자료 : 3개월" +
            "\n전자금융거래법에 따른 보유정보 및 보유기간" +
            "\n전자금융거래에 관한 기록 : 5년" +
            "\n위치정보의 보호 및 이용 등에 관한 법률" +
            "\n개인위치정보에 관한 기록 : 6개월",
    },
    {
        type: "header",
        content: "제15조(개인정보의 파기원칙)",
    },
    {
        type: "para",
        content:
            "회사는 원칙적으로 이용자의 개인정보 처리 목적의 달성, 보유·이용기간의 경과 등 개인정보가 필요하지 않을 경우에는 해당 정보를 지체 없이 파기합니다.",
    },
    {
        type: "header",
        content: "제16조(서비스 미이용자에 대한 개인정보처리)",
    },
    {
        type: "para",
        content:
            "\n① 회사는 1년 동안 회사의 서비스를 이용하지 않은 이용자의 개인정보는 원칙적으로 이용자에게 사전통지하고 개인정보를 파기하거나 별도로 분리하여 저장합니다. " +
            "\n② 회사는 장기 미이용 이용자의 개인정보는 별도로 분리되어 안전하게 보관하게 되며, 해당 이용자의 통지는 분리 보관 처리 일을 기준으로 최소 30일 이전에 SMS로 전송됩니다." +
            "\n③ 장기 미이용 이용자는 회사가 미이용자 DB를 별도로 분리하기 전에 계속 서비스를 이용하고자 하는 경우 S휴먼헬스케어 모바일 앱에 로그인하시면 됩니다." +
            "\n④ 장기 미이용 이용자는 S휴먼헬스케어 모바일 앱에 로그인할 경우 이용자의 동의에 따라 본인의 계정을 복원할 수 있습니다." +
            "\n⑤ 회사는 분리 보관된 개인정보를 4년간 보관 후 지체 없이 파기합니다.",
    },
    {
        type: "header",
        content: "제17조(개인정보파기절차)",
    },
    {
        type: "para",
        content:
            "① 이용자가 회원가입 등을 위해 입력한 정보는 개인정보 처리 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다." +
            "\n② 회사는 파기 사유가 발생한 개인정보를 개인정보보호 책임자의 승인절차를 거쳐 파기합니다",
    },
    {
        type: "header",
        content: "제18조(개인정보파기방법)",
    },
    {
        type: "para",
        content:
            "회사는 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이로 출력된 개인정보는 분쇄기로 분쇄하거나 소각 등을 통하여 파기합니다.",
    },
    {
        type: "header",
        content: "제20조(개인정보 조회 및 수집동의 철회)",
    },
    {
        type: "para",
        content:
            "① 이용자 및 법정 대리인은 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할 수 있으며 개인정보수집 동의 철회를 요청할 수 있습니다." +
            "\n② 이용자 및 법정 대리인은 자신의 가입정보 수집 등에 대한 동의를 철회하기 위해서는 개인정보보호책임자 또는 담당자에게 서면, 전화 또는 전자우편주소로 연락하시면 회사는 지체 없이 조치하겠습니다.",
    },
    {
        type: "header",
        content: "제21조(개인정보 정보변경 등)",
    },
    {
        type: "para",
        content:
            "이용자는 회사에게 제20조의 방법을 통해 개인정보의 오류에 대한 정정을 요청할 수 있습니다." +
            "\n회사는 전항의 경우에 개인정보의 정정을 완료하기 전까지 개인정보를 이용 또는 제공하지 않으며 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.",
    },
    {
        type: "header",
        content: "제22조(이용자의 의무)",
    },
    {
        type: "para",
        content:
            "① 이용자는 자신의 개인정보를 최신의 상태로 유지해야 하며, 이용자의 부정확한 정보 입력으로 발생하는 문제의 책임은 이용자 자신에게 있습니다." +
            "\n② 타인의 개인정보를 도용한 회원가입의 경우 이용자 자격을 상실하거나 관련 개인정보보호 법령에 의해 처벌받을 수 있습니다." +
            "\n③ 이용자는 전자우편주소, 비밀번호 등에 대한 보안을 유지할 책임이 있으며 제3자에게 이를 양도하거나 대여할 수 없습니다.",
    },
    {
        type: "header",
        content: "제23조(회사의 개인정보 관리)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조, 훼손 등이 되지 아니하도록 안전성을 확보하기 위하여 다음과 같이 기술적·관리적 보호대책을 강구하고 있습니다.",
    },
    {
        type: "header",
        content: "제24조(삭제된 정보의 처리)",
    },
    {
        type: "para",
        content:
            '회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 회사가 수집하는 "개인정보의 보유 및 이용기간"' +
            "에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.",
    },
    {
        type: "header",
        content: "제26조(해킹 등에 대비한 대책)",
    },
    {
        type: "para",
        content:
            "① 회사는 해킹, 컴퓨터 바이러스 등 정보통신망 침입에 의해 이용자의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다." +
            "\n② 회사는 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 유출 또는 손상되지 않도록 방지하고 있습니다." +
            "\n③ 회사는 만일의 사태에 대비하여 침입차단 시스템을 이용하여 보안에 최선을 다하고 있습니다." +
            "\n④ 회사는 민감한 개인정보(를 수집 및 보유하고 있는 경우)를 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.",
    },
    {
        type: "header",
        content: "제27조(개인정보 처리 최소화 및 교육)",
    },
    {
        type: "para",
        content:
            "회사는 개인정보 관련 처리 담당자를 최소한으로 제한하며, 개인정보 처리 전담자에 대한 교육 등 관리적 조치를 통해 법령 및 내부방침 등의 준수를 강조하고 있습니다.",
    },
    {
        type: "header",
        content: "제28조(개인정보 유출 등에 대한 조치)",
    },
    {
        type: "para",
        content:
            '회사는 개인정보의 분실·도난·유출(이하 "유출 등"이라 한다) 사실을 안 때에는 지체 없이 다음 각 호의 모든 사항을 해당 이용자에게 알리고 방송통신위원회 또는 한국인터넷진흥원에 신고합니다.' +
            "\n유출 등이 된 개인정보 항목" +
            "\n① 유출 등이 발생한 시점" +
            "\n② 이용자가 취할 수 있는 조치" +
            "\n③ 정보통신서비스 제공자 등의 대응 조치" +
            "\n④ 이용자가 상담 등을 접수할 수 있는 부서 및 연락처",
    },
    {
        type: "header",
        content: "제29조(개인정보 유출 등에 대한 조치의 예외)",
    },
    {
        type: "para",
        content:
            "회사는 전조에도 불구하고 이용자의 연락처를 알 수 없는 등 정당한 사유가 있는 경우에는 회사의 홈페이지에 30일 이상 게시하는 방법으로 전조의 통지를 갈음하는 조치를 취할 수 있습니다. ",
    },
    {
        type: "header",
        content: "제30조(국외 이전 개인정보의 보호)",
    },
    {
        type: "para",
        content:
            "\n① 회사는 이용자의 개인정보에 관하여 개인정보보호법 등 관계 법규를 위반하는 사항을 내용으로 하는 국제계약을 체결하지 않습니다." +
            '\n② 회사는 이용자의 개인정보를 국외에 제공(조회되는 경우를 포함)ㆍ처리위탁ㆍ보관(이하 "이전"이라 함)하려면 이용자의 동의를 받습니다. 다만, 본 조 제3항 각 호의 사항 모두를 개인정보보호법 등 관계 법규에 따라 공개하거나 전자우편 등 대통령령으로 정하는 방법에 따라 이용자에게 알린 경우에는 개인정보 처리위탁ㆍ보관에 따른 동의절차를 거치지 아니할 수 있습니다.' +
            "\n③ 회사는 본조 제2항 본문에 따른 동의를 받으려면 미리 다음 각 호의 사항 모두를 이용자에게 고지합니다." +
            "\n가. 이전되는 개인정보 항목" +
            "\n나. 개인정보가 이전되는 국가, 이전일시 및 이전방법" +
            "\n다. 개인정보를 이전받는 자의 성명(법인인 경우 그 명칭 및 정보관리 책임자의 연락처를 말한다)" +
            "\n라. 개인정보를 이전받는 자의 개인정보 이용목적 및 보유ㆍ이용 기간" +
            "\n④ 회사는 본 조 제2항 본문에 따른 동의를 받아 개인정보를 국외로 이전하는 경우 개인정보보호법 대통령령 등 관계법규에서 정하는 바에 따라 보호조치를 합니다.",
    },
    {
        type: "header",
        content: "제31조(이용자의 쿠키 설치 선택권)",
    },
    {
        type: "para",
        content:
            "이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다." +
            "\n다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 회사의 일부 서비스는 이용에 어려움이 있을 수 있습니다.",
    },
    {
        type: "header",
        content: "제32조(쿠키 설치 허용 지정 방법)",
    },
    {
        type: "para",
        content:
            "쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다." +
            "\n[도구] 메뉴에서 [인터넷 옵션]을 선택합니다." +
            "\n[개인정보 탭]을 클릭합니다." +
            "\n[고급] 설정에서 설정하시면 됩니다.",
    },
    {
        type: "header",
        content: "제33조(회사의 개인정보 보호 책임자 지정)",
    },
    {
        type: "para",
        content:
            "회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다." +
            "\n개인정보 보호 책임자" +
            "\n성명: 이비오" +
            "\n전화번호: 02-2115-8921" +
            "\n이메일: bolee@modim.co.kr" +
            "\n개인정보 보호 담당자" +
            "\n담당부서: 기술연구소" +
            "\n담당자명: 고요한" +
            "\n전화번호: 02-2115-8921" +
            "\n이메일: john@modim.co.kr" +
            "\n회사는 개인정보의 보호를 위해 개인정보보호 전담부서를 운영하고 있으며, 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 해결하고 바로 잡을 수 있도록 최선을 다하고 있습니다.",
    },
];

export default function Policy() {
    return (
        <section className="bg-white-900 py-2 sm:py-2">
            <Container>
                {contents.map((item, index) => {
                    return getContent(item, index);
                })}
            </Container>
        </section>
    );
}
