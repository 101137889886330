import { Container } from "../../components/Container";
import { getContent } from "./components/ContentWidget";

const contents = [
  {
    type: "header",
    content: "제 1조(목적)",
  },
  {
    type: "para",
    content:
      "본 약관은 ㈜모디엠(이하 “회사” 혹은 “당사”라 합니다.)이 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 등을 통해 제공하는 유료 서비스와 관련하여 본 유료 서비스 이용에 대해 회사와 서비스 이용자의 권리, 의무 및 책임 사항 등 필요한 사항을 규정함을 목적으로 합니다.",
  },
  {
    type: "header",
    content: "제 2조 (약관의 명시, 효력 및 변경)",
  },
  {
    type: "para",
    content:
      "① 본 약관은 유료 회원이 당사 모바일 앱(Mobile App, 모바일 어플리케이션)내에서 유료로 제공되는 서비스를 이용하고자 하는 경우, 본 약관에 동의한 유료 서비스 이용자에게 적용됩니다." +
      "\n② 회사는 본 약관의 내용을 유료 회원이 쉽게 확인할 수 있도록 서비스 화면 또는 별도의 연결화면에 게시하거나 기타의 방법으로 공지함으로써 누구나 언제든지 열람할 수 있도록 하였습니다." +
      "\n③ 회사는 필요한 경우 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률 등 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다. 회사는 변경사항을 시행일자 15일 전부터 서비스 이용자들이 알 수 있도록 회사의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 내 공지사항 게시판에서 공지 또는 통지하는 것을 원칙으로 합니다." +
      "\n④ 회사가 ③항에 따라 공지(혹은 통지)를 하면서 공지(혹은 통지)일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다." +
      "\n⑤ 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약의 해지를 선택할 수 있습니다." +
      "\n⑥ 서비스 이용자는 회사의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션)에 접근하여 서비스를 이용할 경우 본 약관 및 관련 운영 정책을 확인 및 준수하고, 약관 변경에 대하여 주의 의무를 다하여야 하며, 변경된 약관의 부지로 인한 이용자의 피해는 회사가 책임지지 않습니다.",
  },
  {
    type: "header",
    content: "제 3조 (약관 외 준칙)",
  },
  {
    type: "para",
    content:
      "본 약관에 명시되지 않은 사항은 이와 관련된 상법, 전기통신사업법, 전자상거래, 저작권법 등에서의 소비자 보호에 관한 법률 등 기타 관련 법령과 상관습에 따릅니다.",
  },
  {
    type: "header",
    content: "제 4조 (용어의 정의)",
  },
  {
    type: "para",
    content:
      "본 약관에서 사용하는 용어의 정의는 다음과 같습니다." +
      "\n① “회사”라 함은, 당사의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 서비스를 개발한 ㈜모디엠을 가리킵니다." +
      '\n② “유료 서비스 이용자” 혹은 “유료 회원”이라 함은, 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션)에 개인 정보를 제공하여 회원 등록을 하고 별도의 금액을 지불을 통해 S휴먼헬스케어 회원권 구매를 하고 등록한 자로서, 당사 웹(Web) 및 모바일 앱(Mobile App)의 정보 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 및 “유료 서비스"를 이용할 수 있는 자를 가리킵니다.' +
      '\n③ "유료 서비스"라 함은, 회사가 유료로 제공하는 제반 서비스를 가리킵니다. ' +
      "\n④ “무료회원”이라 함은, 유료회원이 아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게 부여되는 자격을 가진 회원을 가리킵니다.\n" +
      "\n⑤ “회원탈퇴”라 함은, 회원이 당사 이용약관 또는 회사의 유료 서비스 약관의 체결로 발생한 제반 권리와 의무 등의 법률관계를 자의로 영구히 종료하거나, 포기하는 것을 가리킵니다.",
  },
  {
    type: "header",
    content: "제 5조 (유료 서비스 이용계약의 성립 및 승낙의 제한)",
  },
  {
    type: "para",
    content:
      "\n① 유료 서비스 이용계약은 이 약관에 동의한다는 의사표시(회원 가입 시, 서비스 화면에 게시된 회사 약관을 읽고 동의함)와 함께, S휴먼헬스케어 회원권을 등록하고 회원권 사용 시작이 성립된 순간부터 회사가 제공하는 서비스를 이용할 수 있습니다." +
      "\n② 회원은 유료서비스 이용계약을 체결하기 전에 해당 유료서비스에 대하여 이 약관에서 회사가 명시, 고지하는 사항을 숙지하고 착오 없이 정확히 거래할 수 있도록 하여야 합니다." +
      "\n③ 회사는 당사가 이용약관 정하는 사유가 발생하는 경우, 이용신청을 승낙하지 아니하거나 소정의 조건 성취 또는 제한 사유가 해소될 때까지 일정 기간 동안 승낙을 유보할 수 있습니다." +
      "\n④ “회사”는 XXXXXXX의 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 회사가 문의한 사항에 대해 회원은 성실하게 고지합니다. 회사는 이 경우 수집하는 회원의 정보를 S휴먼헬스케어 이용약관, 개인정보취급방침 및 정보통신망의 이용촉진 및 정보보호 등에 관한 법률이 정한 바에 따라 이용•관리합니다." +
      "\n⑤ 회사는 회원권을 등록하고자 하는 자에 대하여 회원권 등록 승낙을 우선으로 하지만, 다음 각호에 해당하는 자에 한에서 승낙을 거절 할 수 있습니다. " +
      "\n가. 다른 사람의 명의를 사용하여 신청한 경우 " +
      "\n나. 이용계약 신청서의 내용을 허위로 기재하였거나 신청한 경우" +
      "\n다. 회사가 서비스를 제공하지 않은 국가나 지역에서 우회를 통한 접근, 매크로를 통한 입력과 같은 비정상적인 방법을 통해 서비스를 이용하려는 경우" +
      "\n라. 다른 사람의 S휴먼헬스케어 웹(Web) • 모바일 앱(Mobile App) 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 한 경우" +
      "\n마. 공서양속에 어긋나는 행위를 한 경우" +
      "\n바. 당 웹(Web) • 모바일 앱(Mobile App)을 이용하여 법령과 본 약관이 금지하는 행위를 한 경우" +
      "\n사. 기타 상기된 내용에 준하여 회사가 부적합 하다고 판단했을 때, 회원권 사용 승낙을 거절 할 수 있습니다." +
      "\n⑥ S휴먼헬스케어 웹(Web) 및 모바일 앱(Mobile App) 유료 서비스를 이용하고자 하는 유료 회원은 회사에서 요구하는 필요한 정보를 제공해야 합니다. 유료 회원이 허위로 기재한 정보 등 향후 문제가 일어날 만한 정보를 기재함으로써 발생한 문제에 대해서 회사는 일체 책임을 지지 않습니다.   " +
      "\n⑦ 유료 회원의 정보 변경이 발생하면 회사가 정한 절차에 따라 이를 수정해야 할 의무가 있으며, 만약 유료 회원이 개인 정보 변경을 하지 않음으로써 발생하는 문제에 대해서 회사는 일체 책임을 지지 않습니다. " +
      "\n⑧ 유료 회원 계정과 비밀 번호에 대해서는 회원 스스로가 관리를 해야 하며, 이에 대한 책임은 회원에게 있습니다. 특히 유료 회원의 소홀한 관리로 인해 발생한 문제에 대해서 회사는 일체 책임을 지지 않습니다." +
      "\n⑨ 회사는 다음 각 호에 해당하는 경우 그 사유가 소멸될 때까지 이용계약 성립을 유보할 수 있습니다." +
      "\n가. 서비스 관련 제반 용량이 부족한 경우" +
      "\n나. 기술상 장애 사유가 있는 경우",
  },
  {
    type: "header",
    content: "제 6조 (유료 서비스의 종류)",
  },
  {
    type: "para",
    content:
      "\n① 회사는 다음 각 호와 같은 유료서비스를 제공하며, 회사의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다. " +
      "\n가. 서비스 내용 및 속성" +
      "\n- 촬영 서비스: 소변검사 시험지를 판독해주는 기능" +
      "\n나. 서비스 이용기간 및 정기결제 여부에 따른 분류" +
      "\n- 기간 만료형(회원권) 상품: 일정 기간 동안 S휴먼헬스케어 회원의 자격이 주어져 모바일 앱(Mobile App)을 이용할 수 있는 회원권" +
      "\n② 회사는 위에 제시된 유료서비스 이외에 다른 회사와의 계약을 통한 제휴 서비스 등을 제공할 수 있습니다. ",
  },
  {
    type: "header",
    content: "제 7조 (유료 서비스 이용)",
  },
  {
    type: "para",
    content:
      "① 회사가 회원의 회원권 사용 신청을 승낙한 때로부터 유료서비스는 개시되며, 회사의 기술적 사유 등 기타 사정에 의하여 서비스를 개시할 수 없는 경우에는 제3조(약관의 명시, 효력 및 변경 등)의  방법에 따라 회원에게 사전 공지합니다." +
      "\n② 회사는 회원의 사용 신청이 있게 되는 경우, 그 사실을 통지하며, 회사의 통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 불일치 사실을 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약 철회 등에 관한 제9조(유료 서비스 이용계약의 해제 • 해지)의 규정에 따릅니다.",
  },
  {
    type: "header",
    content: "제 8조 (결제 및 결제수단)",
    color: "red",
  },
  {
    type: "para",
    content:
      "① 당사 유료 서비스의 결제방식은 직접결제 방식 한 가지만 존재하며, 결제의 도구로는 아래와 같은 수단이 있습니다. " +
      "\n- 제휴된 신용카드 및 계좌" +
      "\n- 제휴된 통신사 청구서 통합 결제" +
      "\n- 기타 회사가 제3자와 제휴계약 등을 체결하여 회원에게 결제 가능 여부 및 그 방법을 안내하게 되는 결제 수단",
  },
  {
    type: "header",
    content: "제 9조 (유료 서비스 이용 계약의 해제 및 해지)",
  },
  {
    type: "para",
    content:
      "① 유료회원은 해당 회원권을 전혀 사용하지 아니하였을 경우에 한하여 결제일로부터 7일 이내에 웹(Web) 및 모바일 앱(Mobile APP) 내 고객 센터에 게시된 연락 방법을 통해 결제 취소(청약 철회)를 요청할 수 있습니다. 단, 유료회원은 해당 유료서비스 내지 회원권의 내용이 표시 혹은 홍보 내용과 다르거나 계약 내용과 다르게 이행된 경우에는 해당 서비스를 이용 신청한 날과 그 사실을 안 날 또는 알 수 있었던 날로부터 15일 이내에 회사에 결제 취소를 요청 할 수 있습니다. " +
      "\n② 제1항에도 불구하고, 회원은 다음 각 호의 어느 하나에 해당하는 경우에는 청약철회를 할 수 없습니다." +
      "\n가. 회원에게 책임 있는 사유(예 휴대폰 사양, 회원권 훼손 및 분실 등)로 제공된 서비스를 사용할 수 없게 된 경우" +
      "\n다. 회원의 사용 또는 일부 소비에 의하여 서비스 혹은 상품의 가치가 현저히 감소한 경우" +
      "\n③ 청약 철회는 각 고객센터 전자 우편 및 유선 접수 방법으로 할 수 있으며, 회사에 의사를 표시하여 회사에 도달될 때 그 효력이 발생하고, 회사는 회원의 의사표시를 수령한 후 지체 없이 이러한 사실을 회원에게 회신합니다." +
      "\n④ 유료 회원이 회원 탈퇴 등으로 이용계약을 해지한 경우, 해지 당일 및 서비스 최종 종료일까지 재가입이 제한되며, 서비스 종료일 익일(24시간 이후)로부터 재가입이 가능합니다.",
  },
  {
    type: "header",
    content: "제 10조 (유료 서비스 중단 및 변경)",
  },
  {
    type: "para",
    content:
      "① 회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검 및 보수 시에는 유료서비스의 전부 또는 일부의 제공을 제한하거나 일시 중단할 수 있습니다. 이 경우 회사는 가능한 한 그 중단 사유, 중단 기간 등을 이 약관 제3조(약관의 명시, 효력 및 변경)의 방법을 통하여 사전에 회원에게 공지하며, 아래 각 호의 불가피한 경우에는 경위와 원인이 확인된 즉시 사후에 공지할 수 있습니다." +
      "\n가. 회원, 기타 불특정 제3자의 불법, 범죄행위로 인하여 기술적으로 정상적인 서비스의 제공이 어려운 경우" +
      "\n나. 시스템 또는 기타 서비스 설비의 장애, 유무선 네트워크 장애 또는 유료서비스 이용의 폭주 등으로 정상적인 유료서비스 제공이 불가능할 경우" +
      "\n다. 기타 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우" +
      "\n② 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부의 유료서비스를 변경할 수 있고, 변경 전 해당 서비스 초기 화면에 관련사항을 게시합니다. 다만, 변경된 내용이 중대하거나 회원에게 불리한 경우에는 이 약관에서 정한 방법으로 통지하고, 중대하거나 회원에게 불리한 변경 내용에 동의하지 않는 회원은 제9조(유료 서비스 이용 계약의 해제 및 해지)에서 정한 바에 따라 유료서비스 이용계약을 해지할 수 있습니다.",
  },
  {
    type: "header",
    content: "제 11조 (회원에 대한 통지)",
  },
  {
    type: "para",
    content:
      "\n① 회사가 유료 서비스 이용과 관련하여 유료회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 회원의 전자우편주소, 휴대전화 SMS 등으로 할 수 있습니다." +
      "\n② 회사는 유료 서비스 이용과 관련하여 유료회원에 대한 통지의 경우 7일 이상 회사가 운영하는 사이트 내 공지사항 또는 상품 안내페이지에 게시함으로써 제 1항의 통지에 갈음할 수 있습니다. 다만, 유료 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 ①항의 통지를 합니다." +
      "\n③ 회사는 회원이 연락 정보를 기재하지 않았을 경우, 바뀐 정보를 수정하지 않았을 경우 등 개인의 불찰로 인해 개별 통지가 어려운 경우에 한하여 ②항의 공지로써 개별 통지를 한 것으로 간주하여 회사는 이에 일체 책임지지 않습니다.",
  },
  {
    type: "header",
    content: "제 12조 (회사의 의무)",
  },
  {
    type: "para",
    content:
      "① 회사는 관계법령, 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공할 수 있도록 최선을 다하여야 합니다." +
      "\n② 회사는 개인정보 보호를 위한 보안 시스템을 갖추어 유료서비스 이용자가 안전하게 웹(Web) 및 모바일 앱(Mobile App)서비스를 이용할 수 있도록 최선을 다하여야 합니다. " +
      "\n③ 회사는 당사 유료서비스 서비스 이용자의 수신 동의 없이 영리목적으로 광고성 전자우편, SMS, 전화, 우편 등을 발송하지 않는 것을 원칙으로 합니다. " +
      "\n④ 회사는 서버와 설비에 장애가 생기거나 유실된 때에는 지체 없이 바로 수리 혹은 복구합니다. 다만, 천재지변, 전쟁, 비상사태, 사법 문제 발생 등 불가항력 문제가 생길 경우에는 서비스를 일시 중지 또는 영구 중지할 수 있습니다. " +
      "\n⑤ 회사는 유료 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 제11조의 회원에 대한 통지와 같이 동 회원에게 통지합니다." +
      "\n⑥ 회사는 유료 서비스의 운영 및 유지와 관련하여 관계 법령을 준수합니다.",
  },
  {
    type: "header",
    content: "제 13조 (회사의 면책)",
  },
  {
    type: "para",
    content:
      "① 회사는 유료회원이 유료서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다." +
      "\n② 회사는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다." +
      "\n③ 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 및 소프트웨어 운용 과정에서 발생한 고의적인 혹은 고의에 준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애, 외부 공격으로 인한 시스템 장애, 국내외 유명한 보안관련 업체 혹은 연구기관에서 대응 방법이 아직 개발되지 않아 발생한 모든 컴퓨터 및 소프트웨어 관련 문제는 불가항력적 사유라 이로 발생한 손해에 대한 책임이 면제됩니다." +
      "\n④ 회사는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다." +
      "\n⑤ 회사는 ‘회원’의 귀책 사유로 인한 서비스 이용 장애에 대하여 책임이 면제됩니다." +
      "\n⑥ 회사는 무료로 제공되는 서비스 이용과 관련하여 ‘회원’에게 발생한 손해에 대해서는 일체의 민•형사 책임을 지지 않습니다. " +
      "\n⑦ 회사는 회원 상호간 및 회원과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없습니다." +
      "\n⑧ 회사는 회원 상호간 또는 회원과 제 3자 상호간에 발생한 일체의 분쟁에 대하여 책을 지지 않습니다.",
  },
  {
    type: "header",
    content: "부칙",
  },
  {
    type: "para",
    content: "본 약관은 20xx년 xx월 xx일부터 시행됩니다.",
  },
];

export default function ServicePaid() {
  return (
    <section className="bg-white-900 py-2 sm:py-2">
      <Container>
        {contents.map((item, index) => {
          return getContent(item, index);
        })}
      </Container>
    </section>
  );
}
