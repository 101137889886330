import { CallToAction } from "../components/CallToAction";
import { Hero } from "../components/Hero";
import { Introduction } from "./Introduction";
import { Oxygen } from "./Oxygen";
import { Pr } from "./Pr";
import { SHealthcare } from "./SHealthcare";

export default function Home() {
    return (
        <>
            <Hero />
            <SHealthcare />
            <Introduction />
            <CallToAction />
            <Pr />
            <Oxygen />
        </>
    );
}
