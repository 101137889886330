import { Container } from "../../components/Container";
import { getContent } from "./components/ContentWidget";

const contents = [
  {
    type: "para",
    content:
      "(주)모디엠(이하 '회사'라고 합니다)는 개인정보보호법 등 관련 법령상의 개인정보보호 규정을 준수하며 회원의 개인정보보호에 최선을 다하고 있습니다. 회사는 개인정보보호법 제23조에 근거하여, 다음과 같이 민감정보 처리에 관한 동의를 받고자 합니다. ",
  },
  {
    type: "para",
    content: "다음의 내용을 자세히 읽어보신 후 동의 여부를 결정하여 주십시오.",
  },
  {
    type: "header",
    content: "1. 처리하는 민감정보",
  },
  {
    type: "para",
    content:
      "휴대폰 번호, 이메일 주소, 이름, 생년월일, 성별, 본인확인값(CI, DI) 및 내/외국인 여부 키, 몸무게, 항산화관리(활성산소 검사) 검사 결과",
  },
  {
    type: "header",
    content: "2. 민감정보의 처리목적",
  },
  {
    type: "para",
    content:
      "휴대폰 번호, 이메일 주소, 이름, 생년월일, 성별, 본인확인값(CI, DI) 및 내/외국인 여부",
  },
  {
    type: "header",
    content: "3. 키, 몸무게",
  },
  {
    type: "para",
    content:
      "항산화관리(활성산소 검사) 검사 결과에 대한 모든 정보" +
      "\n민감정보의 보유기간 : 민감정보 처리 목적을 달성한 날까지" +
      "\n정보주체의 회원탈퇴일까지",
  },
  {
    type: "header",
    content: "4. 동의 거부 시 불이익 안내",
  },
  {
    type: "para",
    content:
      "가. 회원께서는 민감정보의 처리에 대한 동의를 거부할 권리가 있습니다. 다만, 동의를 거부할 경우서비스 이용제한 및 회원 가입 불가 등의 불이익이 있을 수 있습니다.",
  },
  {
    type: "header",
    content: "5. 민감정보의 제3자 제공",
  },
  {
    type: "para",
    content:
      "① 민감정보를 제공 받는 제3자 : (주)에스휴먼헬스케어" +
      "\n가. 민감정보 제공 목적 : 서비스고도화 개발(예 AI 서비스 개발 ) 등" +
      "\n나. 민감정보 제공 항목 : 휴대폰 번호, 이메일 주소, 이름, 생년월일, 성별, 본인확인값(CI, DI) 및 내/외국인 여부 , 키, 몸무게, 항산화관리(활성산소 검사) 검사 결과에 대한 모든 정보" +
      "\n다. 민감정보 보유 및 이용기간 : 회원 탈퇴시 까지" +
      "\n라. 민감정보 제공 거부 시 불이익 : 서비스 이용 제한" +
      "\n본인은 본 동의서의 내용을 모두 이해하였으며, 민감정보의 처리에 관한 회원 권리 안내문에 관하여 자세한 설명을 듣고 내용에 동의합니다. " +
      "\n그 외 사항은 회사의 개인정보처리방침에 따릅니다.",
  },
];

export default function Info() {
  return (
    <section className="bg-white-900 py-2 sm:py-2">
      <Container>
        {contents.map((item, index) => {
          return getContent(item, index);
        })}
      </Container>
    </section>
  );
}
