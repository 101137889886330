import { Container } from "../../components/Container";
import { getContent } from "./components/ContentWidget";

const privacy = [
    {
        type: "header",
        content: "제 1조 (목적)",
    },
    {
        type: "para",
        content:
            "본 약관은 ㈜모디엠(이하 ‘회사’라 합니다.)이 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 등을 통해 제공하는 서비스와 관련하여 본 서비스 이용에 대해 회사와 서비스 이용자의 권리, 의무 및 책임 사항 등 필요한 사항을 규정함을 목적으로 합니다.",
    },
    {
        type: "header",
        content: "제 2조 (약관의 효력 및 변경) ",
    },
    {
        type: "para",
        content:
            '① 본 약관은 "유료 회원"이 ㈜모디엠이 직접 개발한 모바일 앱(Mobile App, 모바일 어플리케이션) 내에서 제공되는 서비스를 이용하고자 하는 경우, 본 약관에 동의한 회원에게 적용됩니다. (자세한 내용은 제 5조 참고)' +
            "\n② 회사는 본 약관의 내용을 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 서비스 화면에 게시하거나 기타의 방법으로 공지함으로써 누구나 언제든지 열람할 수 있도록 하였습니다. " +
            "\n③ 회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 본 약관을 변경할 수 있습니다. 회사는 변경사항을 시행일자 15일 전부터 서비스 이용자들이 알 수 있도록 회사의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 내 공지사항 게시판에서 공지 또는 통지하는 것을 원칙으로 합니다." +
            "\n④ 회사가 ③항에 따라 공지(혹은 통지)를 하면서 공지(혹은 통지)일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 불구하고 이용자의 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다." +
            "\n⑤ 변경된 약관에 대하여 거부의사를 표시함으로써 이용계약의 해지를 선택할 수 있습니다." +
            "\n⑥ 서비스 이용자는 회사의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션)에 접근하여 서비스를 이용할 경우 본 약관 및 관련 운영 정책을 확인 및 준수하고, 약관 변경에 대하여 주의의무를 다하여야 하며, 변경된 약관의 부지로 인한 이용자의 피해는 회사가 책임지지 않습니다.",
    },
    {
        type: "header",
        content: "제 3조 (약관 외 준칙)",
    },
    {
        type: "para",
        content:
            "본 약관에 명시되지 않은 사항은 이와 관련된 상법, 전기통신사업법, 전자상거래, 저작권법 등에서의 소비자 보호에 관한 법률 등 기타 관련 법령과 상관습에 따릅니다.",
    },
    {
        type: "header",
        content: "제 4조 (용어의 정의)",
    },
    {
        type: "para",
        content:
            "본 약관에서 사용하는 용어의 정의는 다음과 같습니다." +
            "\n① ‘회사’라 함은, S휴먼헬스케어의 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 서비스를 개발한 ㈜모디엠을 가리킵니다." +
            "\n② ‘이용자’ 혹은 ‘회원’이라 함은, 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션)에 개인 정보를 제공하여 회원 등록을 한 자로서, 당 웹(Web)•앱(App)의 정보 및 서비스를 이용할 수 있는 자를 가리킵니다.",
    },
    {
        type: "header",
        content: "제 5조 (`이용 계약의 성립 및 회원가입)",
    },
    {
        type: "para",
        content:
            "① 이용계약은 서비스 이용자가 ‘회원 가입’을 함으로써 서비스 화면에 게시된 회사 약관에 ‘동의’ 하는 것으로 간주하고, ‘회원 가입’이 성립된 순간부터 회사가 제공하는 서비스를 이용할 수 있습니다. " +
            "\n② 회사는 회원가입을 신청하고자 하는 자에 대하여 회원가입 승낙을 우선으로 하지만, 다음 각호에 해당하는 자에 한에서 승낙을 거절 할 수 있습니다. " +
            "\n가. 다른 사람의 명의를 사용하여 신청한 경우 " +
            "\n나. 회원가입 신청서의 내용을 허위로 기재하여 신청한 경우" +
            "\n다. 회사가 서비스를 제공하지 않은 국가나 지역에서 우회를 통한 접근, 매크로를 통한 입력과 같은 비정상적인 방법을 통해 서비스를 이용하려는 경우" +
            "\n라. 다른 사람의 당 웹(Web) • 앱(App) 서비스 이용을 방해하거나 그 정보를 도용하는 등의 행위를 한 경우" +
            "\n마. 공서양속에 어긋나는 행위를 한 경우" +
            "\n바. 당 웹(Web) • 앱(App)을 이용하여 법령과 본 약관이 금지하는 행위를 한 경우" +
            "\n사. 회원이 되고자 하는 자가 이전에 본 약관에 따라 회원자격을 상실한 적이 있는 경우. 다만, ‘회사’의 재가입 승낙을 얻은 경우에는 예외로 합니다" +
            "\n아. 기타 상기된 내용에 준하여 회사가 부적합 하다고 판단했을 때, 회원가입 신청 승낙을 거절 할 수 있습니다." +
            "\n③ 웹(Web) • 앱(APP) 서비스를 이용하는 회원은 회사에서 요구하는 필요한 정보를 제공해야   합니다. 회원이 허위로 기재한 정보 등 향후 문제가 일어날 만한 정보를 기재함으로써 발생한 문제에 대해서 회사는 일체 책임을 지지 않습니다." +
            "\n④ 회원의 정보 변경이 발생하면 회사가 정한 절차에 따라 이를 수정해야 할 의무가 있으며, 만약 회원이 개인 정보 변경을 하지 않음으로써 발생하는 문제에 대해서 회사는 일체 책임을 지지 않습니다." +
            "\n⑤ 회원 계정과 비밀 번호에 대해서는 회원 스스로가 관리를 해야 하며, 이에 대한 책임은 회원에게 있습니다. 소홀한 관리로 인해 발생한 문제에 대해서 회사는 일체 책임을 지지 않습니다." +
            "\n⑥ 회사는 다음 각 호에 해당하는 경우 그 사유가 소멸될 때까지 이용계약 성립을 유보할 수 있습니다." +
            "\n가. 서비스 관련 제반 용량이 부족한 경우" +
            "\n나. 기술상 장애 사유가 있는 경우",
    },
    {
        type: "header",
        content: "제 6조 (회원 탈퇴 및 개인데이터 삭제)",
    },
    {
        type: "para",
        content:
            "① ‘회원’은 ‘회사’에 언제든지 탈퇴를 요철할 수 있으며, ‘회사’는 지체 없이 회원탈퇴 요청을 처리합니다. 다만 이미 체결된 거래계약을 이행할 필요가 있는 경우에는 본 약관이 계속 적용됩니다." +
            "\n② ‘회사’의 서비스를 1년 동안 이용하지 않는 ‘회원’의 경우 휴면계정으로 전환하고 서비스 이용을 제한 할 수 있습니다. " +
            "\n③ 휴면계정 전환 시, 계정 활성을 위해 필요한 아이디(ID), 비밀번호, 이름, 중복가입 방지를 위한 본인 인증값(DI), 휴대전화 번호를 제외한 나머지 정보는 됩니다. 다만, 관계법령에 의해 보존할 필요가 있는 경우 ‘회사’는 정해진 기간 동안 회원정보를 보관합니다. " +
            "\n④ ‘회원’이 모바일 앱(Mobile App, 모바일 어플리케이션)을 사용하면서 발생한 ‘회원’의 데이터는 단순히 모바일 앱(Mobile App, 모바일 어플리케이션)만 삭제할 경우에 서버에 저장된 데이터는 삭제되지 않을 수 있습니다." +
            "\n⑤ ‘회원’이 ‘회사’ 서비스를 탈퇴할 경우, 서버에 저장된 데이터까지 삭제됩니다. 이에 대해 재가입을 할 경우라도 기존의 데이터 복원은 불가합니다. ",
    },
    {
        type: "header",
        content: "제 7조 (회원에 대한 통지)",
    },
    {
        type: "para",
        content:
            "① ‘회사’는 ‘회원’이 회원가입 시 기재한 전자우편, 이동전화번호(문자메시지) 등 적적한 통신 수단을 이용하여 ‘회원’에게 통지 또는 고지를 할 수 있습니다." +
            "\n② ‘회사’가 불특정 다수 ‘회원’에게 통지 또는 고지를 하고자 하는 경우 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 내 게시판 등에 통지 내용을 1주일 이상 게시함으로써 개별 통지에 갈음할 수 있습니다. ",
    },
    {
        type: "header",
        content: "제 8조 (개인정보 보호 및 처리 방침) ",
    },
    {
        type: "para",
        content:
            "회사는 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션)에 회원 가입 시 제공 받습니다." +
            "\n① ‘회사’는 ‘회원’의 정보수집 시, 다음의 필수사항 등 이용 계약 이행에 필요한 최소한의 정보만을 수집합니다. " +
            "\n가. 성명" +
            "\n나. 생년월일" +
            "\n다. 키" +
            "\n라. 몸무개" +
            "\n마. 전화 번호(또는 휴대폰 번호)" +
            "\n바. 아이디(ID)" +
            "\n사. 비밀번호" +
            "\n아. 전자우편(e-mail)주소" +
            "\n② ‘회사’가 개인정보보호법 상의 고유식별정보 및 민감정보를 수집할 때에는 반드시 대상자의  동의를 받습니다." +
            "\n③ ‘회사’는 제공된 개인정보를 ‘회원’의 동의 없이 목적 외 이용, 또는 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 ‘회사’가 집니다. 다만 다음의 경우에는 예외로 합니다." +
            "\n가. 도용방지를 위하여 본인 확인이 필요한 경우" +
            "\n나. 관계법령에 의한 수사 목적으로 관계기관으로부터 요구 받은 경우" +
            "\n다. 주무관청의 요청이 있는 경우" +
            "\n라. 기타 법률의 규정에 따른 적법한 절차에 의한 경우" +
            "\n④ ‘회사’는 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 서비스 회원 가입 시 제공 받은 개인 정보 중 비밀번호를 가지고 있지 않으며, 이와 관련된 부분은 웹(Web) 및 모바일 앱(Mobile App, 모바일 어플리케이션) 내의 개인정보 처리방침에 따릅니다.",
    },
    {
        type: "header",
        content: "제 9조 (회사의 의무)",
    },
    {
        type: "para",
        content:
            "① ‘회사’는 관계법령, 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 약관이 정하는 바에 따라 지속적 • 안정적으로 서비스를 제공하는데 최선을 다하여야 합니다." +
            "\n② ‘회사’는 ‘이용자’가 안전하게 온라인 및 모바일 서비스를 이용할 수 잇도록 개인정보 보호를 위한 보안 시스템을 갖추어야 합니다." +
            "\n③ ‘회사’는 ‘서비스 이용자’의 수신 동의 없이 영리목적으로 광고성 전자우편, 휴대전화 메시지, 전화, 우편 등을 발송하지 않습니다." +
            "\n④ ‘회사’는 서버와 설비에 장애가 생기거나 유실된 때에는 지체 없이 바로 수리 혹은 복구합니다. 다만, 천재지변, 전쟁, 비상사태, 사법 문제 발생 등 부득이한 경우에는 서비스를 일시 중지 또는 영구 중지할 수 있습니다. ",
    },
    {
        type: "header",
        content: "제 10조 (회원의 의무)",
    },
    {
        type: "para",
        content:
            "① 회원이 되고자 하는 자는 회원가입 시 사실에 근거하여 신청서를 작성해야 합니다. 허위, 또는 타인의 정보를 등록한 경우 ‘회사’에 대하여 일체의 권리를 주장할 수 없으며, ‘회사’는 이로 인하여 발생한 손해에 대하여 일체 책임을 부담하지 않습니다. " +
            "\n② ‘회원’은 본 약관에서 규정하는 사항, 기타 관계 법령, ‘회사’가 정한 제반 규정 및 공지사항을 준수하여야 합니다. 또한 ‘회원’은 ‘회사’의 업무를 방해하는 행위 및 ‘회사’의 명예를 훼손하는 행위를 해서는 안 됩니다.  " +
            "\n③ ‘회원’은 ‘회사’의 동의가 없는 한 서비스의 이용 권한, 기타 이용계약상 지위를 타인에게 양도 및 증여를 할 수 없을 뿐만 아니라 이를 담보로 제공할 수 없습니다. " +
            "\n④ ‘회원’은 주소, 연락처, 전자우편 주소 등 회원정보가 변경된 경우, 즉시 이를 수정해야 합니다.  변경된 정보를 수정하지 않거나 수정을 게을리하여 발생하는 책임은 모두 ‘회원’이 부담합니다." +
            "\n⑤ ‘회원’은 본인의 개인 정보를 타 회원 및 타인에게 공개해서는 안되고, 타 회원의 개인정보를 수집, 저장, 공개하는 행위를 해서는 안됩니다." +
            "\n⑥ ‘회원’은 관계 법령에 위배되거나 공서양속에 반하는 행위를 해서는 안됩니다. " +
            "\n⑦ ‘회원’의 계정 및 모바일 앱(Mobile App, 모바일 어플리케이션)을 사용하는 모바일 기기에 관한 책임은 ‘회원’에게 있습니다. 이로 인해 발생하는 문제에 대해서 회사는 일체 책임을 지지 않습니다.",
    },
    {
        type: "header",
        content: "제 11조 (회사의 면책)",
    },
    {
        type: "para",
        content:
            "① ‘회사’는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지 않습니다." +
            "\n② ‘회사’는 천재지변, 전쟁, 기간통신사업자의 서비스 중지 및 기타 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다." +
            "\n③ ‘회사’는 서비스용 설비의 보수, 교체, 정기점검, 공사 및 소프트웨어 운용 과정에서 발생한 고의적인 혹은 고의에 준하는 중대한 과실 없이 발생할 수 있는 시스템의 장애, 외부 공격으로 인한 시스템 장애, 국내외 유명한 보안관련 업체 혹은 연구기관에서 대응 방법이 아직 개발되지 않아 발생한 모든 컴퓨터 및 소프트웨어 관련 문제는 불가항력적 사유라 이로 발생한 손해에 대한 책임이 면제됩니다." +
            "\n④ ‘회사’는 회원의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 회원이 신상정보 및 전자우편 주소를 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다." +
            "\n⑤ ‘회사’는 ‘회원’의 귀책 사유로 인한 서비스 이용 장애에 대하여 책임이 면제됩니다." +
            "\n⑥ ‘회사’는 무료로 제공되는 서비스 이용과 관련하여 ‘회원’에게 발생한 손해에 대해서는 일체의 민사•형사 책임을 지지 않습니다. " +
            "\n⑦ ‘회사’는 회원 상호간 및 회원과 제 3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없고, 이로 인한 손해를 배상할 책임도 없습니다." +
            "\n⑧ ‘회사’는 회원 상호간 또는 회원과 제 3자 상호간에 발생한 일체의 분쟁에 대하여 책을 지지 않습니다.",
    },
    {
        type: "header",
        content: "부칙",
    },
    {
        type: "para",
        content: "① 본 약관은 2023년 12월 01일부터 적용됩니다.",
    },
];

export default function Service() {
    return (
        <section className="bg-white-900 py-2 sm:py-2">
            <Container>
                {privacy.map((item, index) => {
                    return getContent(item, index);
                })}
            </Container>
        </section>
    );
}
