import { Link } from "react-router-dom";
import { Container } from "../components/Container";

const articles = [
    {
        no: 1,
        title: "에스휴먼헬스케어, 모디엠과 소변 기반 스마트 자가검사 키트 사업 위한 MOU",
        description:
            "[메디컬투데이=최민석 기자] 에스휴먼헬스케어(대표 김도형)는 모디엠(대표 박현열)과 최근 소변 기반 스마트 자가검사 키트인 S휴먼헬스케어 사업 번영을 위한 업무협약(MOU)을 체결했다고 6일 밝혔다. 양사는 건강과 노화에 관심이 증가하는 것에 반해 국내 의료 사각지대가 확대되는 등 건강 및 복지 관련 사회 문제가 수면위로 떠오르자, 기업의 역량이 필요한 시기라고 생각했다. 이에 해결책을 제시하기 위해 양사의 기술과 경험을 통합해 S휴먼헬스케어를 개발했다고 밝혔다. ",
            // "1 (주) 모디엠과 수본바이오가 손을 잡고 함께 소변 기반 스마트 검사 키트 개발에 들어간다. asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasd (주) 모디엠과 수본바이오가 손을 잡고 함께 소변 기반 스마트 검사 키트 개발에 들어간다. asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasd",
        date: "Nov 06 2023", //new Date().toDateString(),
        imageUrl:
            "https://mdtcdn.iwinv.biz/news/data/20231106/p1065600120482088_834_thum.jpg"
    },
    {
        no: 2,
        title: "에스휴먼헬스케어, 모디엠과 소변 기반 스마트 자가검사 키트 사업 위한 MOU 체결",
        description:
            "[바이오타임즈] 에스휴먼헬스케어(대표 김도형)는 모디엠(대표 박현열)과 손잡고 지난달 17일 소변 기반 스마트 자가검사 키트인 S휴먼헬스케어 사업 번영을 위한 업무협약(MOU)을 체결했다고 7일 밝혔다.",
            // "2 (주) 모디엠과 수본바이오가 손을 잡고 함께 소변 기반 스마트 검사 키트 개발에 들어간다. 2) S휴먼 헬스케어 대해 더 알아보기. 여기 처기 알가면 정보가 많이 나옵니다. 그래서 빨리 찾아요. 부탁",
        date: "Nov 07 2023", //new Date().toDateString(),
        imageUrl:"/images/logo_type_256.png"
    },
    // {
    //     no: 3,
    //     title: "", //"(주) 모디엠",
    //     description:
    //     "33333", //"3 (주) 모디엠과 수본바이오가 손을 잡고 함께 소변 기반 스마트 검사 키트 개발에 들어간다. asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasd (주) 모디엠과 수본바이오가 손을 잡고 함께 소변 기반 스마트 검사 키트 개발에 들어간다. asdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdasd",
    //     date: "", //new Date().toDateString(),
    //     imageUrl:"/images/logo_type_256.png"
    // },
    // {
    //     no: 4,
    //     title: "", //"(주) 모디엠",
    //     description: "44444444", //"4 Admin",
    //     date: "", //new Date().toDateString(),
    //     imageUrl: "/images/logo_type_256.png"
    // },
];

export function Pr() {
    return (
        <section id="pr" aria-labelledby="pr-title" className="border-t border-gray-200 py-20 sm:py-32">
            <Container>
                <article id={articles[0].title} className="relative isolate flex flex-col gap-8 lg:flex-row">
                    <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-48 lg:shrink-0">
                        <img
                            src={articles[0].imageUrl}
                            alt=""
                            className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                        />
                        <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                    </div>
                    <div className="w-1/2">
                        <div className="h-4/5">
                            <div className="flex items-center gap-x-4 text-xs">
                                <time dateTime={articles[0].date} className="text-gray-500">
                                    {articles[0].date}
                                </time>
                            </div>
                            <div className="group relative max-w-2xl h-full">
                                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                    <a href={articles[0].href}>
                                        <span className="absolute inset-0" />
                                        {articles[0].title}
                                    </a>
                                </h3>
                                <p className="mt-4 line-clamp-3 overflow-clip text-sm leading-6 text-gray-600">{articles[0].description}</p>
                            </div>
                        </div>
                        <div className="flex mt-4 justify-end align-baseline border-t border-gray-900/5">
                            <Link to={`/news/${articles[0].no}`} key={articles[0].no} className="text-sm font-semibold leading-6 text-indigo-600" aria-describedby="featured-post">
                                자세히 읽기 {/*Continue reading*/}<span aria-hidden="true">&rarr;</span>
                            </Link>
                        </div>
                    </div>
                </article>
                <div className="overflow-hidden relative w-full">
                    <div className="mt-8 space-y-1 lg:mt-8 lg:space-y-1 grid max-w-full grid-cols-3 gap-4 sm:mt-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 sm:grid-cols-2">
                        {articles.map((post, index) => {
                            if (index !== 0) {
                                return (
                                    <article key={post.title} className="relative isolate max-w-lg flex flex-col gap-8 lg:flex-row">
                                        <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:w-36 lg:aspect-square">
                                            <img
                                                src={post.imageUrl}
                                                alt=""
                                                className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                            />
                                            {/* <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" /> */}
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <div className="flex items-center gap-x-4 text-xs">
                                                <time dateTime={post.datetime} className="text-gray-500">
                                                    {post.date}
                                                </time>
                                            </div>
                                            <div className="group relative max-w-xs">
                                                <h3 className="mt-2 text-base font-semibold overflow-hidden leading-6 text-gray-900 group-hover:text-gray-600">
                                                    <Link to={`/news/${post.no}`} key={post.no}>
                                                        <span className="absolute inset-0" />
                                                        {post.title}
                                                    </Link>
                                                </h3>
                                                <p className="mt-2 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                                            </div>
                                        </div>
                                    </article>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </div>
            </Container>
        </section>
    );
}
