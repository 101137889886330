import { useLocation } from "react-router-dom";
import { Container } from "../../components/Container";
import Service from "./Service";
import Policy from "./Policy";
import Info from "./Info";
import ServicePaid from "./ServicePaid";

const tabs = [
    { name: "서비스 이용약관", href: "/service" },
    // { name: "유료 서비스 이용약관", href: "/service/paid" },
    { name: "개인정보 처리방침", href: "/policy" },
    { name: "민감정보 처리방침", href: "/info" },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function PrivacyContainer({ children }, ...props) {
    const location = useLocation();

    return (
        <section className="bg-white-900 py-20 sm:py-32">
            <Container>
                <div>
                    <div className="sm:hidden">
                        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                            // defaultValue={tabs.find((tab) => tab.current).name}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                            {tabs.map((tab, tabIdx) => (
                                <a
                                    key={tab.name}
                                    href={tab.href}
                                    className={classNames(
                                        location.pathname === tab.href ? "text-gray-900" : "text-gray-500 hover:text-gray-700",
                                        tabIdx === 0 ? "rounded-l-lg" : "",
                                        tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                                        "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                                    )}
                                    aria-current={tab.current ? "page" : undefined}
                                >
                                    <span>{tab.name}</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            location.pathname === tab.href ? "bg-indigo-500" : "bg-transparent",
                                            "absolute inset-x-0 bottom-0 h-0.5"
                                        )}
                                    />
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>

                {location.pathname === "/service" && <Service />}
                {location.pathname === "/service/paid" && <ServicePaid />}
                {location.pathname === "/policy" && <Policy />}
                {location.pathname === "/info" && <Info />}
            </Container>
        </section>
    );
}
