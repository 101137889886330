import { Link } from "react-router-dom";
import { Container } from "./Container";
import { ReactComponent as Logo } from "../assets/svg/logo_header.svg";
import { ReactComponent as QrCode } from "../assets/svg/qrCode.svg";
import { NavLinks } from "./NavLinks";
import { APP_NAME } from "../utils/Common.js";

function QrCodeBorder(props) {
    return (
        <svg viewBox="0 0 96 96" fill="none" aria-hidden="true" {...props}>
            <path
                d="M1 17V9a8 8 0 0 1 8-8h8M95 17V9a8 8 0 0 0-8-8h-8M1 79v8a8 8 0 0 0 8 8h8M95 79v8a8 8 0 0 1-8 8h-8"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
}

const navigations = [
    { name: "이용약관", href: "/service" },
    { name: "개인정보 처리방침", href: "/policy" },
    { name: "민감정보 처리방침", href: "/info" },
];

export function Footer() {
    return (
        <footer className="border-t border-gray-200">
            <Container>
                <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
                    <div>
                        <div className="flex items-center text-gray-900">
                            <Logo />
                            <div className="ml-4">
                                <p className="text-base font-semibold">{APP_NAME}</p>
                                <p className="mt-1 text-sm">나와 우리가족의 건강/노화 관리</p>
                            </div>
                        </div>
                        <nav className="mt-11 flex gap-8">
                            <NavLinks />
                        </nav>
                    </div>
                    <div className="group relative -mx-4 flex items-center self-stretch p-4 transition-colors hover:bg-gray-100 sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6">
                        <div className="relative flex h-24 w-24 flex-none items-center justify-center">
                            <QrCodeBorder className="absolute inset-0 h-full w-full stroke-gray-300 transition-colors group-hover:stroke-cyan-500" />
                            <QrCode />
                            {/* <Image src={} alt="" unoptimized /> */}
                        </div>
                        <div className="ml-8 lg:w-64">
                            <p className="text-base font-semibold text-gray-900">
                                <Link href="#">
                                    <span className="absolute inset-0 sm:rounded-2xl" />
                                    Download the app
                                </Link>
                            </p>
                            <p className="mt-1 text-sm text-gray-700">Scan the QR code to download the app from the App Store.</p>
                        </div>
                    </div>
                </div>
                {/* <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6"> */}
                <div className="mb-12 border-t border-white/10 pt-8 sm:mb:14 md:flex md:items-center md:justify-start lg:mb:12">
                    <p className="text-sm text-gray-500 md:mt-0">
                        &copy; Copyright {new Date().getFullYear()}. All rights reserved. <b>Modim LLC</b>
                    </p>
                    <div className="flex space-x-6 md:order-2 ml-24">
                        {navigations.map((item, index) => (
                            <>
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className="text-gray-500 hover:text-gray-900 hover:font-bold hover:underline"
                                >
                                    <span className="text-sm">{item.name}</span>
                                </a>
                                {navigations.length - 1 !== index && <span className="text-gray-300"> | </span>}
                            </>
                        ))}
                    </div>
                    <div />
                </div>
            </Container>
        </footer>
    );
}
