import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import krJSON from "./locales/kr/translation.json";
import enJSON from "./locales/en/translation.json";

const resources = {
    kr: {
        translation: krJSON,
    },
    en: {
        translation: enJSON,
    },
};

i18n.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: "kr",
    debug: true,
    // lng: localStorage.getItem("i18n") ?? "kr",
});

export default i18n;
