import { Fragment, useEffect, useId, useRef, useState } from "react";
import { Container } from "../components/Container";
import { Tab } from "@headlessui/react";
import { useDebouncedCallback } from "use-debounce";
import { AnimatePresence, motion } from "framer-motion";
import { CircleBackground } from "../components/CircleBackground";
import { PhoneFrame } from "../components/PhoneFrame";

const features = [
    {
        name: "간편성",
        description:
            "S휴먼헬스케어와 스마트폰만 있으면 언제 어디서나 현재 내 몸속 항산화 상태를 알 수 있도록 개발했습니다.",
            // "S휴먼 헬스케어와 스마트 폰만 있으면 언제 어디서나 내 몸의 건강 상태와 노화 상태를 알 수 있도록 도와주는 것을 목적으로 두고 현재 개발 중에 있습니다.",
        icon: ConvenienceIcon,
    },
    {
        name: "정확성",
        description:
            "시험지 색을 정확하게 판별해 주는 색상 교정 알고리즘과 촬영과 측정에 영향을 미치는 여러 환경적인 요인을 최소화해주는 S휴먼헬스케어 측정판 개발을 통해 검사의 정확도를 높였습니다.",
            // "4년 동안 연구한 요 시험지를 정확하게 판별해내는 색상교정(calibration) 알고리즘과 요 시험지 측정에 영향을 주는 여러 환경요인 영향을 최소화 시켜주는 마커(marker) 개발을 통해서 측정의 정확도를 높였습니다.",
        icon: AccurancyIcon,
    },
    {
        name: "직관성",
        description:
            "결과값을 정성적, 정량적 수치 두 가지로 보여주기 때문에 좀 더 직관적으로 알 수 있습니다. 검사에 대한 결과는 모바일 앱 내에 저장되어 최근 5회부터 1년의 변화를 그래프로 보여주므로 내 몸속 활성산소 변화 추이를 쉽게 볼 수 있습니다.",
            // "결과값을 정성적 및 정량적 수치 두 가지 값으로 보여주기 때문에 육안으로 확인했을 때보다 좀 더 직관적으로 내 몸의 상태를 알 수 있습니다.",
        icon: DeviceUserIcon,
    },
    {
        name: "휴대성",
        description:
            "개별 포장으로 보관하기 용이할 뿐만 아니라 높은 휴대성으로 외출이나 여행 시, 언제 어디서나 검사할 수 있도록 구성하였습니다.",
            // "개별 포장으로 되어 있기 때문에 휴대성이 높다는 것과 더불어 보관하기 쉽다는 장점이 있습니다.따라서 외출할 때나 장거리 여행을 갈 때에도 휴대할 수 있어 몸의 불편함을 느끼면 언제든지 검사할 수 있도록 구성했습니다.",
        icon: DeviceTouchIcon,
    },
];

function DeviceUserIcon(props) {
    return (
        <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
            <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
                fill="#737373"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
                fill="#A3A3A3"
            />
        </svg>
    );
}

function DeviceTouchIcon(props) {
    let id = useId();

    return (
        <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
            <defs>
                <linearGradient id={`${id}-gradient`} x1={14} y1={14.5} x2={7} y2={17} gradientUnits="userSpaceOnUse">
                    <stop stopColor="#737373" />
                    <stop offset={1} stopColor="#D4D4D4" stopOpacity={0} />
                </linearGradient>
            </defs>
            <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
                fill="#A3A3A3"
            />
            <path
                d="M7 22c0-4.694 3.5-8 8-8"
                stroke={`url(#${id}-gradient)`}
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
                fill="#A3A3A3"
            />
        </svg>
    );
}

function ConvenienceIcon(props) {
    return (
        <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
            <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
                fill="#A3A3A3"
            />
        </svg>
    );
}

function AccurancyIcon(props) {
    return (
        <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
            <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
                fill="#A3A3A3"
            />
        </svg>
    );
}

function usePrevious(value) {
    let ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}

function Features() {
    let [changeCount, setChangeCount] = useState(0);
    let [selectedIndex, setSelectedIndex] = useState(0);
    let prevIndex = usePrevious(selectedIndex);
    let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

    let onChange = useDebouncedCallback(
        (selectedIndex) => {
            setSelectedIndex(selectedIndex);
            setChangeCount((changeCount) => changeCount + 1);
        },
        100,
        { leading: true }
    );

    return (
        <Tab.Group
            as="div"
            className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
            selectedIndex={selectedIndex}
            onChange={onChange}
            vertical
        >
            <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
                {features.map((feature, featureIndex) => (
                    <div key={feature.name} className="relative rounded-2xl transition-colors hover:bg-gray-800/30">
                        {featureIndex === selectedIndex && (
                            <motion.div
                                layoutId="activeBackground"
                                className="absolute inset-0 bg-gray-800"
                                initial={{ borderRadius: 16 }}
                            />
                        )}
                        <div className="relative z-10 p-8">
                            <feature.icon className="h-8 w-8" />
                            <h3 className="mt-6 text-lg font-semibold text-white">
                                <Tab className="text-left ui-not-focus-visible:outline-none">
                                    <span className="absolute inset-0 rounded-2xl" />
                                    {feature.name}
                                </Tab>
                            </h3>
                            <p className="mt-2 text-sm text-gray-400">{feature.description}</p>
                        </div>
                    </div>
                ))}
            </Tab.List>
            <div className="relative col-span-6">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                    <CircleBackground color="#13B5C8" className="animate-spin-slower" />
                </div>
                <PhoneFrame className="z-10 mx-auto w-full max-w-[366px]">
                    <Tab.Panels as={Fragment}>
                        <AnimatePresence initial={false} custom={{ isForwards, changeCount }}>
                            {features.map((feature, featureIndex) =>
                                selectedIndex === featureIndex ? (
                                    <Tab.Panel
                                        static
                                        key={feature.name + changeCount}
                                        className="col-start-1 row-start-1 flex focus:outline-offset-[32px] ui-not-focus-visible:outline-none"
                                    >
                                        <img className="mx-auto" src="/images/convenience_screen.jpg" alt="" />
                                        {/* <feature.screen animated custom={{ isForwards, changeCount }} /> */}
                                    </Tab.Panel>
                                ) : null
                            )}
                        </AnimatePresence>
                    </Tab.Panels>
                </PhoneFrame>
            </div>
        </Tab.Group>
    );
}

export function SHealthcare() {
    return (
        <section id="features" aria-label="Features for investing all your money" className="bg-gray-900 py-20 sm:py-32">
            <Container>
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
                    <h2 className="text-3xl font-medium tracking-tight text-white">S휴먼헬스케어 제공하는 네 가지 편의</h2>
                    <p className="mt-2 text-lg text-gray-400">
                        우리가 몸에 이상을 느껴 병원에 방문해 소변검사를 할 때면, <br />
                        컵에 직접 소변을 받아 제출하고, 일정 시간을 기다려야 결과를 알게 되는 수고가 있었습니다. <br />
                        S휴먼헬스케어는 사람들의 수고를 덜어주고, 편의를 제공하기 위해 개발되었습니다. 

                        {/* 우리가 몸에 이상을 느껴 병원에 가서 소변검사를 할 때면. <br />
                        직접 컵에 소변을 받아 제출하고, 다시 일정 시간 기다려야
                        <br /> 결과를 알게 되는 수고스러움이 있었습니다. <br />
                        우리가 몸에 이상을 느껴 병원에 가면 늘 소변검사를 합니다. */}
                    </p>
                </div>
            </Container>
            <Container className="hidden md:mt-20 md:block">
                <Features />
            </Container>
        </section>
    );
}
