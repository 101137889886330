import { useTranslation } from "react-i18next";
import "./App.css";
import Home from "./pages/Home";
import { Route, Routes } from "react-router-dom";
import PrivacyContainer from "./pages/privacy/PrivacyContainer";
import News from "./pages/News";
import Article from "./pages/Article";

function App() {
  const { ready } = useTranslation();

  if (ready) {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/service" element={<PrivacyContainer />} />
        <Route path="/info" element={<PrivacyContainer />} />
        <Route path="/policy" element={<PrivacyContainer />} />
        <Route path="/service/paid" element={<PrivacyContainer />} />
        <Route path="/news/:no" element={<News />} />
        <Route path="/article/:no" element={<Article />} />
      </Routes>
    );
  }

  return <span>Loading...</span>;
}

export default App;
