import { AnimatePresence, motion } from "framer-motion";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HashLink as Link } from "react-router-hash-link";

export function NavLinks() {
    const { t } = useTranslation();
    let [hoveredIndex, setHoveredIndex] = useState();
    let timeoutRef = useRef();

    return [
        ["navs.healthcare", "#features"],
        ["navs.introduction", "#introduction"],
        ["navs.pr", "#pr"],
        ["navs.oxygen", "#oxygen"],
    ].map(([label, link], index) => (
        <Link
            key={label}
            to={link}
            className="relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm text-gray-700 transition-colors delay-150 hover:text-gray-900 hover:delay-0"
            onMouseEnter={() => {
                if (timeoutRef.current) {
                    window.clearTimeout(timeoutRef.current);
                }
                setHoveredIndex(index);
            }}
            onMouseLeave={() => {
                timeoutRef.current = window.setTimeout(() => {
                    setHoveredIndex(null);
                }, 200);
            }}
        >
            <AnimatePresence>
                {hoveredIndex === index && (
                    <motion.span
                        className="absolute inset-0 rounded-lg bg-gray-100"
                        layoutId="hoverBackground"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1, transition: { duration: 0.15 } }}
                        exit={{
                            opacity: 0,
                            transition: { duration: 0.15 },
                        }}
                    />
                )}
            </AnimatePresence>
            <span className="relative z-10">{t(label)}</span>
        </Link>
    ));
}
