import { isEmpty } from "lodash";

export const getContent = (item, index) => {
  console.log("ITEM ==> ", item.color);
  switch (item.type) {
    case "header":
      return (
        <h3
          className={
            "text-gray-800 font-semibold text-lg " + index !== 0
              ? "mt-8 "
              : "" + !isEmpty(item.color)
              ? "text-red-600 "
              : ""
          }
        >
          {item.content}
        </h3>
      );
    case "para":
      return (
        <span
          className={
            "flex mt-4 lg:mt-4 text-gray-500 text-sm whitespace-pre-line leading-6 text-justify"
          }
        >
          {item.content}
        </span>
      );
    default:
      break;
  }
};
